.selectedMovieView{
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* border: 3px solid pink; */
}

.selectedMovieView .panel{
    width: 150%;
    max-width: 25vw;

    max-height: 100%;
    padding: 25px;
    background-color: var(--red-darker);
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.selectedMovieView  .details{
    position: relative;
    margin-bottom: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
}


.selectedMovieView .details   img.back-icon{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.selectedMovieView  .details   img.back-icon:hover{
    background-color: var(--whitish-hover);
    cursor: pointer;
}

.selectedMovieView .details   h4 {
    margin: 0 40px;
}


.selectedMovieView .poster-container{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
}

.selectedMovieView .poster-container img{
    max-width: min(100%, 30vh);
    max-height: 100%;
    padding: 10px;
}

.selectedMovieView .add-to-purchaise{
    padding: 10px;
}

.selectedMovieView .add-to-purchaise > span{
    width: max-content;
    margin: 0 auto;
    padding: 0 25px;
    line-height: 1.7em;
}
.selectedMovieView .add-to-purchaise > span:hover{
    background-color: var(--blue-theme);
    
}

.selectedMovieView .add-to-purchaise .quantity-input{
    width:4ch;
    color: var(--text-red);
    border-color: var(--text-red);
    text-align: center;
}

.selectedMovieView .add-to-purchaise .quantity-input:hover{
    background-color: var(--whitish-hover);
}

.selectedMovieView .add-to-purchaise button{
    width: 120px;
    margin-top: 5px;
}

.selectedMovieView .add-to-purchaise button:disabled{
    cursor: wait;
}

@media (max-width: 1024px) {

    .selectedMovieView .panel{
        width: auto;
        max-width: 100%;
        padding: 10px;
    }
    .selectedMovieView .poster-container{
        max-height: 70%;
    }
} 

@media (max-width: 640px) {
    .panel .details h4{
        font-size: 24px;
    }

    .panel .add-to-purchaise button{
        font-size: 24px;
        line-height: 30px;
    }

}