.backdrop{

    border: 3px solid indigo;

    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;
    
    z-index: 10;
}

.backdrop > .qty-panel{
    background-color: var(--red-theme);
    width: 300px;
    height: 200px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.backdrop > .qty-panel > .row{
    width: 70%;
    margin: 10px;

    display: flex;
    justify-content:space-between;
    align-items: center;
}

.backdrop > .qty-panel > .row > button{
    margin: auto 0;
}

.backdrop > .qty-panel > .row > button:disabled{
    background-color: transparent;
}

.backdrop > .qty-panel > .row > span{
    display: block;
    margin: auto 10px;
    flex-grow: 10;
    text-align: center;
}

.backdrop > .qty-panel > .row > span:hover{
    background-color: transparent;
}

.backdrop > .qty-panel >  .primary-red-btn{
    width: 70%;
}

.backdrop > .qty-panel > .primary-red-btn {
    margin: 10px;
}