/*                       Layout                      */
/* ================================================= */

.search-styles.text-area {
  grid-column: 1 / 7;
  grid-row: 4 / 6;
}

.search-styles.dynamic-area {
  grid-column: 8 / 11;
  grid-row: 1 / 11;

  position: relative; 
}

/*                      .dynamic-area                 */
/* ================================================= */
.search-styles.dynamic-area .search-view{
  transition: 0.7s ease-in-out;
}

.search-styles.dynamic-area .search-view.withoutResults {
  padding-top: 26.1vh;
}

.search-styles.dynamic-area .search-view.withResults {
  padding-top: 5vh;
}

.search-styles.dynamic-area form {
  display: flex;
  flex-direction: column;
}

.search-styles.dynamic-area .search-view form label{
  padding-left: 10px;
}

.search-styles.dynamic-area .search-view form input{
  margin-top: 3px;
}

/*            .dynamic-area  > .search-output         */
/* ================================================= */

.search-styles .search-output {
  max-height: 60vh;
  text-align: center;

  background: var(--red-darker);
  border: 10px solid var(--red-darker);
  border-radius: 5px;
  overflow: hidden;
}

.search-styles .search-output li {
  margin-right: 14px;
}

.search-styles .search-output .page-navigation{
  padding-bottom: 10px;
}

.search-styles .search-output .page-navigation * {
    margin: 10px;
}

.search-styles .search-output .page-navigation .btn:disabled {
  background-color: transparent;
}

.search-styles .search-output .page-navigation span.btn.page-counter {
  cursor: default;
}
.search-styles .search-output .page-navigation span.btn.page-counter:hover{
  background-color: transparent;
}

/*      simpleBar(scrollbar)    custom styles       */
/* ================================================ */

.search-styles.dynamic-area  .search-output > div[data-simplebar] {
  max-height: inherit;
  /* margin: 10px 5px; */
}

.simplebar-track .simplebar-scrollbar.simplebar-visible:before {
  background-color: var(--text-red);
  opacity: 1;
}

.simplebar-scrollbar {
  margin-left: 3px;
}

@media (max-width: 1024px) {

  .search-styles.text-area {
    grid-column: 2 / 10;
    grid-row: 1 / 3;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .search-styles.dynamic-area{
    grid-column: 2 / 10;
    grid-row: 3 / 10;

  }
  
  .search-styles.dynamic-area .search-view.withResults {
    padding-top: 5%;
  } 

  .search-styles.dynamic-area .search-view.withoutResults{
    padding-top: 40%;
  }

  .search-styles.dynamic-area .search-view form {
    margin-top: 0;
    width: 300px;
    margin: 0 auto;
  }

  .search-styles.dynamic-area .search-view .search-output {
    max-height: 50vh;
  }
  
  .search-styles.dynamic-area .search-view .search-output .page-navigation{
    width: max-content;
    margin: 0 auto;
  }
}

@media (max-width: 640px) {

  .search-styles.text-area {
    grid-column: 1 / 11;
    grid-row: 1 / 3;

  }
  
  .search-styles.dynamic-area {
    grid-column: 1 / 11;
    grid-row: 3 / 10;

    /* border: 3px solid hotpink; */
  }

  .search-styles.dynamic-area form {
    width: 80%;
  }

}