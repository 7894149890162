/*      Layout       */
.upload-styles.text-area {
    grid-column: 1 / 7;
    grid-row: 4 / 6;
}
  
.upload-styles.dynamic-area {
    grid-column: 8 / 11;
    grid-row: 1 / 11;
  
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.upload-styles.dynamic-area form{   
    width: 100%;
    transition: 1s;
}

.upload-styles.dynamic-area form.noFile{  
    padding-top: 26.1vh;
}

.upload-styles.dynamic-area form.chosenFile{  
    padding-top: 3vh;
}

.upload-styles.dynamic-area form > label{
    margin-left: 10px;
    
}
.upload-styles.dynamic-area label > input[type=file]{
    display: none;
}

.upload-styles.dynamic-area label > .poster-file-name{
    margin: 3px 0 10px 0;
    width: 100%;
    min-height: 1.2em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
}

.upload-styles.dynamic-area .error-msg{
    margin: 0 10px;
}

.upload-styles.dynamic-area .poster-preview{
    margin: 5% 0;
    max-height: calc(500vh / 10);
    overflow: hidden;
    display: flex;
    justify-content: center;
    text-align: center;
}

.upload-styles.dynamic-area .poster-preview > img{
 
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.upload-styles.dynamic-area span.successfully-added{
    display: block;
    width:50%;
    margin: 0 auto;
    text-align: center;
}

.upload-styles.dynamic-area .row-flex-panel{
    display: flex;
    justify-content:center;
    align-items: center;

    margin-bottom: 5%;
}

.upload-styles.dynamic-area label{
    padding-right: 5px;
}

.upload-styles.dynamic-area   input[type=text]{
    width: 7ch;
    margin-right: 10px;
    margin-bottom: 0;
}

.upload-styles.dynamic-area  input[type=text]#custom-name{
    width: 50%;
    min-width: 150px;
}

.upload-styles.dynamic-area  input[type=text]#custom-qty{
    width: 4ch;
}

.upload-styles.dynamic-area   input[type=text].invalid{
    background-color:#c6534b;
}

/*      Info pop-up       */
/* ====================== */

.upload-styles.dynamic-area .info-container{
    position: relative;
    height: 20px;
}
.upload-styles.dynamic-area .info-container img{
    height: 100%;

}
.upload-styles.dynamic-area .info-container:hover{
    cursor: pointer;
}
.upload-styles.dynamic-area .info-container > .info-content:hover{ 
    cursor: default;
}

.upload-styles.dynamic-area .info-container > .info-content{ 
    position: absolute;
    background: rgba(177,101,97, 0.92);
    border: 3px solid var(--text-red);
    padding: 10px;
    width: 400px;
    bottom: 0;
    margin: 35px;
    transform: translateX(-97%);
    opacity: 0;
    visibility: hidden;
    transition:  0.1s ease-out;
    
}

/* To be able to move cursor on the text */
.upload-styles.dynamic-area .info-container > .info-content:after{
    content:'';
    position: absolute;
    height: 10px;
    width: 100%;
    bottom: -10px;
} 

.upload-styles.dynamic-area .info-container:hover > .info-content{ 
    visibility: visible;
    opacity: 100%;
}

/* END    Info pop-up     */
/* ====================== */



.upload-styles.dynamic-area .price{
    
    font-size: 1.5em;
    padding: 0 10px;
    white-space: nowrap; 

}

.upload-styles.dynamic-area  button{
    width: 6ch;
}
/* .upload-styles.dynamic-area .price{
    width: max-content;
    font-size: 1.5em;
    margin: 0 auto;
}

.upload-styles.dynamic-area  button{
    width: 6ch;
} */


@media (max-width: 1024px) {

    .upload-styles.text-area {
      grid-column: 1 / 11;
      grid-row: 1 / 3;
  
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      text-align: center;
    }
    
    .upload-styles.dynamic-area{
        grid-column: 3 / 9;
        grid-row: 3 / 10;

        display: flex;
        justify-content: center;
        align-items: flex-start;

        /* border: 3px solid pink; */
    }

    .upload-styles.dynamic-area form{
        transition: 0.5s;
        padding-bottom: 50px;
    }

    .upload-styles.dynamic-area form.chosenFile{
        padding-top: 20px;
    }
    
    .upload-styles.dynamic-area form.noFile{
        padding-top: 15%;
    }

    .upload-styles.dynamic-area form > label{
        display: block;
        width: 300px;
        max-width: 100%;
        margin: 0 auto;
    }   

    .upload-styles.dynamic-area .poster-preview {
        max-height: calc(500vh / 15);
        min-height: 300px;
        margin-top: 0px;
    }

}


@media (max-width: 640px) {

    .upload-styles.dynamic-area{
        grid-column: 2 / 10;
        grid-row: 3 / 10;

        
    }

}










