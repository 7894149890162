/* ======      HEADER    ======== */

header {
    min-width: var(--min-app-w);
    height: var(--top-menu-h);
    padding: 0 var(--lr-space-general);
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
}

header > .side-frame {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0 auto;
}

#logo {
    position: absolute;
    height: 70%;
    min-height: 70px;
    z-index: 5;
    bottom: 15%;
}

#logo > img {
    height: 100%;
}

/*       Top menu      */

nav {
    position: absolute;
    right: 0;
    bottom: 0;
}

nav > ul {
    display: flex;
    flex-direction: row;
    align-items:flex-end;
}

nav > ul > li > a {
    display: block;
    text-align: center;
    border-bottom: 2px solid transparent;
    width: var(--lr-space-general);
    min-width: 95px;
    padding: 4px 0;
}

nav > ul > li > a:hover{
    border-bottom: 2px solid var(--blue-theme);
} 

/*       Shopping bag menu      */

nav > ul > li > a > #shоpping-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

nav > ul > li > a > #shоpping-container > * {
    margin: 4px 10px;
}

nav > ul > li > a > #shоpping-container > #shopping-counter {
    background-color: var(--blue-theme);
    color: var(--whitish-theme);
    width: 30px;
    height: 30px;
    line-height: 32px;
    border-radius: 50%;
}

/* ============================================================== */

nav  .sub-menu {
    position: absolute;
    z-index: 30;
    text-align: center;
}

nav:full-screen  .sub-menu li:first-child {
    padding-top: 5px;
}

nav  .sub-menu li a{
    display: block;
    background-color: var(--red-theme);
    line-height: 2.3em;
    /* margin: 5px 0; */
    border-bottom: 2px solid transparent;
    width: var(--lr-space-general);
    min-width: 95px;
}

nav  .sub-menu li a:hover{
    border-bottom: 2px solid var(--blue-theme);
}

/*      Hamburger / Close menu icon       */

#hamburger-icon {
    display: none;
    position: absolute;
    height: 30px;
    width: 30px;
    margin-right: 5px;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: 10;
}

#hamburger-icon > a {
    display: flex;
    height: 100%;
}

.hidden {
    display: none;
}

.shown {
    display: block;
}

@media (max-width: 640px) {
    #hamburger-icon {
        display: block;
    }
    nav {
        display: none;
    }
    nav.full-screen {
        display: block;
        position: relative;
        width: 100vw;
        height: 100vh;
        right: var(--lr-space-general);
        padding-left: var(--lr-space-general);
        padding-top: var(--top-menu-h);
        background-color: var(--red-theme);
        z-index: 3;
    }

     nav.full-screen > ul {
        margin-top: 1vh;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:flex-start;
    }

    nav.full-screen .sub-menu{
        position: relative;
    }
   
   
    
    
    nav.full-screen li > a {
        display: flex;
        justify-content: left;
        align-items: center;
        color: var(--text-red);
        font-family: "Carter One", cursive;
        font-weight: normal;
        font-size: 27px;
        width: auto;
        text-align: left;
        margin: 0;
        padding: 0;
        height: 45px;
        /* margin-bottom: 1vh; */
        border-bottom: 3px solid transparent;
    } 



    nav.full-screen .sub-menu li{
        margin-left: 4vw;
        width: 80vw;
       
    }

    nav.full-screen li a:hover{
        border-bottom: 3px solid transparent;
    }

}