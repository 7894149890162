@import url(https://fonts.googleapis.com/css2?family=Carter+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);
/* ======      HEADER    ======== */

header {
    min-width: var(--min-app-w);
    height: var(--top-menu-h);
    padding: 0 var(--lr-space-general);
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
}

header > .side-frame {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0 auto;
}

#logo {
    position: absolute;
    height: 70%;
    min-height: 70px;
    z-index: 5;
    bottom: 15%;
}

#logo > img {
    height: 100%;
}

/*       Top menu      */

nav {
    position: absolute;
    right: 0;
    bottom: 0;
}

nav > ul {
    display: flex;
    flex-direction: row;
    align-items:flex-end;
}

nav > ul > li > a {
    display: block;
    text-align: center;
    border-bottom: 2px solid transparent;
    width: var(--lr-space-general);
    min-width: 95px;
    padding: 4px 0;
}

nav > ul > li > a:hover{
    border-bottom: 2px solid var(--blue-theme);
} 

/*       Shopping bag menu      */

nav > ul > li > a > #shоpping-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

nav > ul > li > a > #shоpping-container > * {
    margin: 4px 10px;
}

nav > ul > li > a > #shоpping-container > #shopping-counter {
    background-color: var(--blue-theme);
    color: var(--whitish-theme);
    width: 30px;
    height: 30px;
    line-height: 32px;
    border-radius: 50%;
}

/* ============================================================== */

nav  .sub-menu {
    position: absolute;
    z-index: 30;
    text-align: center;
}

nav:full-screen  .sub-menu li:first-child {
    padding-top: 5px;
}

nav  .sub-menu li a{
    display: block;
    background-color: var(--red-theme);
    line-height: 2.3em;
    /* margin: 5px 0; */
    border-bottom: 2px solid transparent;
    width: var(--lr-space-general);
    min-width: 95px;
}

nav  .sub-menu li a:hover{
    border-bottom: 2px solid var(--blue-theme);
}

/*      Hamburger / Close menu icon       */

#hamburger-icon {
    display: none;
    position: absolute;
    height: 30px;
    width: 30px;
    margin-right: 5px;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: 10;
}

#hamburger-icon > a {
    display: flex;
    height: 100%;
}

.hidden {
    display: none;
}

.shown {
    display: block;
}

@media (max-width: 640px) {
    #hamburger-icon {
        display: block;
    }
    nav {
        display: none;
    }
    nav.full-screen {
        display: block;
        position: relative;
        width: 100vw;
        height: 100vh;
        right: var(--lr-space-general);
        padding-left: var(--lr-space-general);
        padding-top: var(--top-menu-h);
        background-color: var(--red-theme);
        z-index: 3;
    }

     nav.full-screen > ul {
        margin-top: 1vh;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:flex-start;
    }

    nav.full-screen .sub-menu{
        position: relative;
    }
   
   
    
    
    nav.full-screen li > a {
        display: flex;
        justify-content: left;
        align-items: center;
        color: var(--text-red);
        font-family: "Carter One", cursive;
        font-weight: normal;
        font-size: 27px;
        width: auto;
        text-align: left;
        margin: 0;
        padding: 0;
        height: 45px;
        /* margin-bottom: 1vh; */
        border-bottom: 3px solid transparent;
    } 



    nav.full-screen .sub-menu li{
        margin-left: 4vw;
        width: 80vw;
       
    }

    nav.full-screen li a:hover{
        border-bottom: 3px solid transparent;
    }

}
/*

  @media (max-width: 1024px) {}
  @media (max-width: 768px) {}
  @media (max-width: 640px) {}

*/

/* Variables */

:root {
  --red-theme: #b67873;
  --red-darker: #b16561;
  --text-red: #940b01;
  --blue-theme: #154c9f;
  --blue-hover: #2a61b4;

  --whitish-theme: #f9f0f0;
  --whitish-hover: rgba(255, 255, 255, 0.15);

  --lr-space-general: calc(100vw / 12);
  --top-menu-h: calc(100vh / 6);
  --min-app-w: 400px;
}

/* Resetting styles */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  width: 100vw;
  max-width: 1920px;
  min-width: 400px;
  min-width: var(--min-app-w);
  margin: 0 auto;
  height: 100vh;
  min-height: 700px;
  background-color: #b67873;
  background-color: var(--red-theme);

  overflow-x: hidden;
}


label {
  color: #f9f0f0;
  color: var(--whitish-theme);
}

input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 3px solid #154c9f;
  border: 3px solid var(--blue-theme);
  background: transparent;
  margin-bottom: 5%;
  padding: 5px;
  outline: none;

  color: #154c9f;

  color: var(--blue-theme);
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

h1 {
  font-size: clamp(3.375rem, 2.0893rem + 2.0089vw, 4.5rem);
  font-weight: normal;
}
h4 {
  font-size: clamp(1.6875rem, 1.0446rem + 1.0045vw, 2.25rem);
  font-weight: normal;
  line-height: 1.4;
}
@media (max-width: 768px) {
  h1 {
    /* font-size: clamp(1.6875rem, 0.1985rem + 6.6176vw, 3.375rem); */
    /* font-size: clamp(1.6875rem, -0.7005rem + 8.4906vw, 3.375rem); */
    /* font-size: clamp(1.777rem, -0.4843rem + 8.0403vw, 3.375rem); */
    font-size: clamp(2.25rem, 0.6580rem + 5.6604vw, 3.375rem);

  }
  h4 {
    font-size: clamp(1rem, 0.3934rem + 2.6961vw, 1.6875rem);
  }
}

ul{
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #154c9f;
  color: var(--blue-theme);
}

a:hover {
  cursor: pointer;
}

.content-in-grid-10x10 {
  /* if next line is applied qty-modal will be disturbed */
  /* position: relative; */   

  min-width: 400px;   

  min-width: var(--min-app-w);
  height: calc(100vh - calc(100vh / 6));
  height: calc(100vh - var(--top-menu-h));
  padding: 0 calc(100vw / 12);
  min-height: 500px;

  color: #940b01;

  color: var(--text-red);
  font-family: "Carter One", cursive;

  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(10, 1fr);

  /* border: 3px solid hotpink; */

}

.err-msg{
  text-align: center;
}


.btn {
  display: inline-block;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-family: "Carter One", cursive;
  font-size: clamp(1rem, 0.3934rem + 2.6961vw, 1.6875rem);
  box-shadow: none;
  border: 3px solid transparent;

  margin: 0 10px;
  padding: 0 10px;
  line-height: 1.3em;
}

.btn:focus{
  outline: none;
}

/* Blue pallette */

.primary-blue-btn {
  color: #f9f0f0;
  color: var(--whitish-theme);
  background-color: #154c9f;
  background-color: var(--blue-theme);
  border-color: #154c9f;
  border-color: var(--blue-theme);
}

.primary-blue-btn:hover {
  background-color: #2a61b4;
  background-color: var(--blue-hover);
}

.secondary-blue-btn {
  color: #154c9f;
  color: var(--blue-theme);
  background-color: transparent;
  border-color: #154c9f;
  border-color: var(--blue-theme);
}

.secondary-blue-btn:hover {
  background-color: rgba(255, 255, 255, 0.15);
  background-color: var(--whitish-hover);
}

/* Red pallette */

.primary-red-btn {
  color: #f9f0f0;
  color: var(--whitish-theme);
  background-color: #940b01;
  background-color: var(--text-red);
  border-color: #940b01;
  border-color: var(--text-red);
}

.primary-red-btn:hover {
  background-color: #b16561;
  background-color: var(--red-darker);
}

.secondary-red-btn {
  color: #940b01;
  color: var(--text-red);
  background-color: transparent;
  border-color: #940b01;
  border-color: var(--text-red);
}

.secondary-red-btn:hover {
  background-color: rgba(255, 255, 255, 0.15);
  background-color: var(--whitish-hover);
}

/* @media (max-width: 768px) {
  button[type="submit"].btn.primary-blue-btn{
    line-height: 1.5em;
    width: 25vw;
    min-width: 7ch;
  }
} */

/* min-width: 1024px */

#message {
  grid-row: 3 / 6;
  grid-column: 1 / 8;
}

#actions {
  grid-row: 6 / 11;
  grid-column: 1 / 8;
}
#actions>button{
  width: 210px;
  line-height: 2em;
}

#actions .primary-blue-btn {
  margin-bottom: 10%;
}

#actions .secondary-blue-btn {
  margin-left: 5%;
}

#poster {
  grid-row: 2/ 10;
  grid-column: 8 / -1;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#poster > img {
  max-width: 100%;
  max-height: 100%;
}

@media (max-width: 1024px) {
  #message {
    grid-row: 1 / 4;
    grid-column: 1 / -1;

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #actions {
    grid-row: 4 / 10;
    grid-column: 1 / 6;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  #actions > .btn {
    margin-right: 10%;
  }

  #poster {
    grid-row: 4 / 10;
    grid-column: 6 / 11;

    justify-content: flex-start;
  }
  #poster > img {
    margin-left: 10%;
  }
}
@media (max-width: 768px) {
  #message {
    grid-row: 2 / 4;
    grid-column: 1 / -1;

    justify-content: flex-start;
  }

  #poster {
    grid-row: 4 / 9;
    grid-column: 1 / -1;

    flex-direction: column;
    justify-content: center;
  }
  #poster > img {
    margin: 0;
    padding: 0 0 10%;
  }
  #actions {
    grid-row: 9 / 11;
    grid-column: 1 / -1;

    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
  }
  #actions > .btn {
    width: 30vw;
    min-width: 127px;
    margin: 0;
  }
}
@media (max-width: 640px) {
  #actions > .btn {
    justify-content: space-around;
  }

}


h1.authentication-styles {
  grid-column: 1/7;
  grid-row: 1/9;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

form.authentication-styles {
  grid-column: 7/-1;
  grid-row: 3 / 9;

  border: 1px solid var(--red-darker);
  border-radius: 50px;
  background-color: var(--red-darker);
  padding: 0 15%;
  min-height: -webkit-max-content;
  min-height: -moz-max-content;
  min-height: max-content;
  

  display: flex;
  flex-direction: column;
  justify-content: center;
}

form.authentication-styles label{
    margin: 5px 0;
}
form.authentication-styles input{
     margin-bottom: 20px;
}

form.authentication-styles button[type="submit"] {
  align-self: center;
  margin: 20px 0 15px;
  width: calc(5ch + 20%);
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  height: 1.8em;
  min-height: 40px;
  padding: 0 5%;
}

form.authentication-styles .auth-link{
  color: var(--blue-theme);
  align-self: center;
  font-size: 16px;
}

form.authentication-styles .forgot-password-link:hover{
  	color: var(--blue-hover)
}

form.authentication-styles .success-msg{
  color: var(--blue-theme)
}

@media (max-width: 1024px) {
  h1.authentication-styles {
    grid-column: 1 / -1;
    grid-row: 2 / 4;

    flex-direction: column;
    justify-content: flex-end;
  }

  form.authentication-styles {
    grid-column: 3 / 9;
    grid-row: 5 / 10;

    padding: 0 15%;
  }

}

@media (max-width: 768px) {
  h1.authentication-styles {
    grid-column: 1 / -1;
    grid-row: 1 / 4;
    justify-content: center;
    text-align: center;
  }

  form.authentication-styles {
    grid-column: 2 / 10;
    grid-row: 4 / 9;

  }

}

@media (max-width: 640px) {
  form.authentication-styles .auth-link{
    font-size: 14px;
  }
}
/*                       Layout                      */
/* ================================================= */

.search-styles.text-area {
  grid-column: 1 / 7;
  grid-row: 4 / 6;
}

.search-styles.dynamic-area {
  grid-column: 8 / 11;
  grid-row: 1 / 11;

  position: relative; 
}

/*                      .dynamic-area                 */
/* ================================================= */
.search-styles.dynamic-area .search-view{
  transition: 0.7s ease-in-out;
}

.search-styles.dynamic-area .search-view.withoutResults {
  padding-top: 26.1vh;
}

.search-styles.dynamic-area .search-view.withResults {
  padding-top: 5vh;
}

.search-styles.dynamic-area form {
  display: flex;
  flex-direction: column;
}

.search-styles.dynamic-area .search-view form label{
  padding-left: 10px;
}

.search-styles.dynamic-area .search-view form input{
  margin-top: 3px;
}

/*            .dynamic-area  > .search-output         */
/* ================================================= */

.search-styles .search-output {
  max-height: 60vh;
  text-align: center;

  background: var(--red-darker);
  border: 10px solid var(--red-darker);
  border-radius: 5px;
  overflow: hidden;
}

.search-styles .search-output li {
  margin-right: 14px;
}

.search-styles .search-output .page-navigation{
  padding-bottom: 10px;
}

.search-styles .search-output .page-navigation * {
    margin: 10px;
}

.search-styles .search-output .page-navigation .btn:disabled {
  background-color: transparent;
}

.search-styles .search-output .page-navigation span.btn.page-counter {
  cursor: default;
}
.search-styles .search-output .page-navigation span.btn.page-counter:hover{
  background-color: transparent;
}

/*      simpleBar(scrollbar)    custom styles       */
/* ================================================ */

.search-styles.dynamic-area  .search-output > div[data-simplebar] {
  max-height: inherit;
  /* margin: 10px 5px; */
}

.simplebar-track .simplebar-scrollbar.simplebar-visible:before {
  background-color: var(--text-red);
  opacity: 1;
}

.simplebar-scrollbar {
  margin-left: 3px;
}

@media (max-width: 1024px) {

  .search-styles.text-area {
    grid-column: 2 / 10;
    grid-row: 1 / 3;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .search-styles.dynamic-area{
    grid-column: 2 / 10;
    grid-row: 3 / 10;

  }
  
  .search-styles.dynamic-area .search-view.withResults {
    padding-top: 5%;
  } 

  .search-styles.dynamic-area .search-view.withoutResults{
    padding-top: 40%;
  }

  .search-styles.dynamic-area .search-view form {
    margin-top: 0;
    width: 300px;
    margin: 0 auto;
  }

  .search-styles.dynamic-area .search-view .search-output {
    max-height: 50vh;
  }
  
  .search-styles.dynamic-area .search-view .search-output .page-navigation{
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: 0 auto;
  }
}

@media (max-width: 640px) {

  .search-styles.text-area {
    grid-column: 1 / 11;
    grid-row: 1 / 3;

  }
  
  .search-styles.dynamic-area {
    grid-column: 1 / 11;
    grid-row: 3 / 10;

    /* border: 3px solid hotpink; */
  }

  .search-styles.dynamic-area form {
    width: 80%;
  }

}
.movieSuggestion{
   
    background-color: var(--red-darker);
    border-radius: 5px;
    padding: 10px 10px;
    display:flex;
    justify-content: space-between;
}

.movieSuggestion.selectable{
    cursor: pointer;
    border: 2px solid transparent;
}

.movieSuggestion.unselectable{
    cursor: not-allowed;
}

.movieSuggestion.unselectable .no-poster{
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
}

.movieSuggestion.selectable:hover{
    background-color: var(--red-theme);
}

.movieSuggestion>.info{
    text-align: right;
}
.selectedMovieView{
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* border: 3px solid pink; */
}

.selectedMovieView .panel{
    width: 150%;
    max-width: 25vw;

    max-height: 100%;
    padding: 25px;
    background-color: var(--red-darker);
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.selectedMovieView  .details{
    position: relative;
    margin-bottom: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
}


.selectedMovieView .details   img.back-icon{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.selectedMovieView  .details   img.back-icon:hover{
    background-color: var(--whitish-hover);
    cursor: pointer;
}

.selectedMovieView .details   h4 {
    margin: 0 40px;
}


.selectedMovieView .poster-container{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
}

.selectedMovieView .poster-container img{
    max-width: min(100%, 30vh);
    max-height: 100%;
    padding: 10px;
}

.selectedMovieView .add-to-purchaise{
    padding: 10px;
}

.selectedMovieView .add-to-purchaise > span{
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: 0 auto;
    padding: 0 25px;
    line-height: 1.7em;
}
.selectedMovieView .add-to-purchaise > span:hover{
    background-color: var(--blue-theme);
    
}

.selectedMovieView .add-to-purchaise .quantity-input{
    width:4ch;
    color: var(--text-red);
    border-color: var(--text-red);
    text-align: center;
}

.selectedMovieView .add-to-purchaise .quantity-input:hover{
    background-color: var(--whitish-hover);
}

.selectedMovieView .add-to-purchaise button{
    width: 120px;
    margin-top: 5px;
}

.selectedMovieView .add-to-purchaise button:disabled{
    cursor: wait;
}

@media (max-width: 1024px) {

    .selectedMovieView .panel{
        width: auto;
        max-width: 100%;
        padding: 10px;
    }
    .selectedMovieView .poster-container{
        max-height: 70%;
    }
} 

@media (max-width: 640px) {
    .panel .details h4{
        font-size: 24px;
    }

    .panel .add-to-purchaise button{
        font-size: 24px;
        line-height: 30px;
    }

}
/*      Layout       */
.upload-styles.text-area {
    grid-column: 1 / 7;
    grid-row: 4 / 6;
}
  
.upload-styles.dynamic-area {
    grid-column: 8 / 11;
    grid-row: 1 / 11;
  
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.upload-styles.dynamic-area form{   
    width: 100%;
    transition: 1s;
}

.upload-styles.dynamic-area form.noFile{  
    padding-top: 26.1vh;
}

.upload-styles.dynamic-area form.chosenFile{  
    padding-top: 3vh;
}

.upload-styles.dynamic-area form > label{
    margin-left: 10px;
    
}
.upload-styles.dynamic-area label > input[type=file]{
    display: none;
}

.upload-styles.dynamic-area label > .poster-file-name{
    margin: 3px 0 10px 0;
    width: 100%;
    min-height: 1.2em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
}

.upload-styles.dynamic-area .error-msg{
    margin: 0 10px;
}

.upload-styles.dynamic-area .poster-preview{
    margin: 5% 0;
    max-height: calc(500vh / 10);
    overflow: hidden;
    display: flex;
    justify-content: center;
    text-align: center;
}

.upload-styles.dynamic-area .poster-preview > img{
 
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.upload-styles.dynamic-area span.successfully-added{
    display: block;
    width:50%;
    margin: 0 auto;
    text-align: center;
}

.upload-styles.dynamic-area .row-flex-panel{
    display: flex;
    justify-content:center;
    align-items: center;

    margin-bottom: 5%;
}

.upload-styles.dynamic-area label{
    padding-right: 5px;
}

.upload-styles.dynamic-area   input[type=text]{
    width: 7ch;
    margin-right: 10px;
    margin-bottom: 0;
}

.upload-styles.dynamic-area  input[type=text]#custom-name{
    width: 50%;
    min-width: 150px;
}

.upload-styles.dynamic-area  input[type=text]#custom-qty{
    width: 4ch;
}

.upload-styles.dynamic-area   input[type=text].invalid{
    background-color:#c6534b;
}

/*      Info pop-up       */
/* ====================== */

.upload-styles.dynamic-area .info-container{
    position: relative;
    height: 20px;
}
.upload-styles.dynamic-area .info-container img{
    height: 100%;

}
.upload-styles.dynamic-area .info-container:hover{
    cursor: pointer;
}
.upload-styles.dynamic-area .info-container > .info-content:hover{ 
    cursor: default;
}

.upload-styles.dynamic-area .info-container > .info-content{ 
    position: absolute;
    background: rgba(177,101,97, 0.92);
    border: 3px solid var(--text-red);
    padding: 10px;
    width: 400px;
    bottom: 0;
    margin: 35px;
    transform: translateX(-97%);
    opacity: 0;
    visibility: hidden;
    transition:  0.1s ease-out;
    
}

/* To be able to move cursor on the text */
.upload-styles.dynamic-area .info-container > .info-content:after{
    content:'';
    position: absolute;
    height: 10px;
    width: 100%;
    bottom: -10px;
} 

.upload-styles.dynamic-area .info-container:hover > .info-content{ 
    visibility: visible;
    opacity: 100%;
}

/* END    Info pop-up     */
/* ====================== */



.upload-styles.dynamic-area .price{
    
    font-size: 1.5em;
    padding: 0 10px;
    white-space: nowrap; 

}

.upload-styles.dynamic-area  button{
    width: 6ch;
}
/* .upload-styles.dynamic-area .price{
    width: max-content;
    font-size: 1.5em;
    margin: 0 auto;
}

.upload-styles.dynamic-area  button{
    width: 6ch;
} */


@media (max-width: 1024px) {

    .upload-styles.text-area {
      grid-column: 1 / 11;
      grid-row: 1 / 3;
  
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      text-align: center;
    }
    
    .upload-styles.dynamic-area{
        grid-column: 3 / 9;
        grid-row: 3 / 10;

        display: flex;
        justify-content: center;
        align-items: flex-start;

        /* border: 3px solid pink; */
    }

    .upload-styles.dynamic-area form{
        transition: 0.5s;
        padding-bottom: 50px;
    }

    .upload-styles.dynamic-area form.chosenFile{
        padding-top: 20px;
    }
    
    .upload-styles.dynamic-area form.noFile{
        padding-top: 15%;
    }

    .upload-styles.dynamic-area form > label{
        display: block;
        width: 300px;
        max-width: 100%;
        margin: 0 auto;
    }   

    .upload-styles.dynamic-area .poster-preview {
        max-height: calc(500vh / 15);
        min-height: 300px;
        margin-top: 0px;
    }

}


@media (max-width: 640px) {

    .upload-styles.dynamic-area{
        grid-column: 2 / 10;
        grid-row: 3 / 10;

        
    }

}











.shopping-page.text-area  {
    grid-column: 1 / 7;
    grid-row: 4 / 6;
}

.shopping-page.dynamic-area  {
    grid-column: 8 / 11;
    grid-row: 1 / 11;
    
    margin-top: calc(100vh / 12);  /* 10% of the grid (5/6vh) */

    display: flex;
    flex-direction: column;
}

.shopping-page.dynamic-area > .items-container {
    height: 70%;
    padding-top: 13px;
    background: var(--red-darker);
    border-radius: 5px;
}

.shopping-page.dynamic-area > .items-container > .empty-bag {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*      simpleBar(scrollbar)    custom styles       */
/* ================================================ */

.shopping-page.dynamic-area > .items-container > div[data-simplebar] {
    height: 100%;
}

.simplebar-track .simplebar-scrollbar.simplebar-visible:before {
    background-color: var(--text-red);
    opacity: 1;
}

.simplebar-scrollbar {
    margin-left: 2px;
}

/* ================================================ */

.shopping-page.dynamic-area  .closing-deal {
    height: 30%;
    
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
   
}

.shopping-page.dynamic-area > .closing-deal > .overall-price {
    text-align: right;
    border: 3px solid var(--text-red);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    margin: 10px;
    padding: 0 1ch;
    cursor: default;
    align-self: center;
}

.shopping-page.dynamic-area > .closing-deal > button {
    display: block;
    margin: 0 auto;
    line-height: 2em;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    width: 50%;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
}


.shopping-page.dynamic-area > .closing-deal > button:disabled {
    background-color: var(--text-red);
}

@media (max-width: 1024px) {

    .shopping-page.text-area  {
        grid-column: 2 / 10;
        grid-row: 1 / 3;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }
    
    .shopping-page.dynamic-area  {
        grid-column: 4 / 8;
        grid-row: 3 / 10;

        /* padding-top: 60px; */
    }

}

@media (max-width: 768px) {


    .shopping-page.dynamic-area  {
        grid-column: 3 / 9;
        grid-row: 3 / 10;

    }
}

.purchase-item {
    border: 3px solid var(--red-theme);
    border-radius: 5px;
    margin: 0 10px 10px;
    padding: 10px;

    display: flex;
    justify-content: space-between;
}

.purchase-item > .image-container {
    line-height: 0;
    margin-right: 10px;
}

.purchase-item > .item-details{
    text-align: right;
    position: relative;
    overflow:hidden;

}
.purchase-item > .item-details > .movie-title{
    overflow:hidden;
    white-space: nowrap; 
    text-overflow:ellipsis;
}

.purchase-item > .item-details > .movie-qty {
    overflow:hidden;
    white-space: nowrap; 
    text-overflow:ellipsis;
}

.purchase-item > .item-details > .movie-qty:hover{
    cursor: pointer;
}

.purchase-item > .item-details  .bucket-icon{
    height: 22px;
    margin: 5px 0 0 auto;
    display: block;

}

.purchase-item > .item-details  .bucket-icon:hover{
    
    cursor: pointer;
    background-color: var(--red-theme);

}

@media (max-width: 1024px) {





}


.backdrop{

    border: 3px solid indigo;

    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;
    
    z-index: 10;
}

.backdrop > .qty-panel{
    background-color: var(--red-theme);
    width: 300px;
    height: 200px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.backdrop > .qty-panel > .row{
    width: 70%;
    margin: 10px;

    display: flex;
    justify-content:space-between;
    align-items: center;
}

.backdrop > .qty-panel > .row > button{
    margin: auto 0;
}

.backdrop > .qty-panel > .row > button:disabled{
    background-color: transparent;
}

.backdrop > .qty-panel > .row > span{
    display: block;
    margin: auto 10px;
    flex-grow: 10;
    text-align: center;
}

.backdrop > .qty-panel > .row > span:hover{
    background-color: transparent;
}

.backdrop > .qty-panel >  .primary-red-btn{
    width: 70%;
}

.backdrop > .qty-panel > .primary-red-btn {
    margin: 10px;
}
.purchase-response-message{
    grid-column: 1 / -1;
    grid-row: 4 / 6;
}
.home-panel{
    position: absolute;
    width: 100%;
    left: 0;   /* this line must be here and i dont know why  */
}

.home-panel > .merit{
    width: calc(100vw - calc(var(--lr-space-general) * 2));
    margin: 10vh auto;

    display: flex;
    justify-content: space-between;
    align-items: center;

}

.reverse {
    flex-direction: row-reverse;
}

.home-panel > .merit > .message {
    width: 57%;
}


.home-panel > .merit > .img-frame {
    width: 40%;
    max-height: 40vh;
    display: flex;
    justify-content: center;
}

.home-panel > .merit > .img-frame > img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;

}

@media (max-width: 1024px) {
    .home-panel > .merit{
        flex-direction:column;
    }

    .home-panel > .merit > .message {
        width: 100%;
        margin-top: 5vh;
    }
}

@media (max-width: 768px) {
    .home-panel > .merit > .img-frame {
        width: 80%;
    }
}



