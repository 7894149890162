.shopping-page.text-area  {
    grid-column: 1 / 7;
    grid-row: 4 / 6;
}

.shopping-page.dynamic-area  {
    grid-column: 8 / 11;
    grid-row: 1 / 11;
    
    margin-top: calc(100vh / 12);  /* 10% of the grid (5/6vh) */

    display: flex;
    flex-direction: column;
}

.shopping-page.dynamic-area > .items-container {
    height: 70%;
    padding-top: 13px;
    background: var(--red-darker);
    border-radius: 5px;
}

.shopping-page.dynamic-area > .items-container > .empty-bag {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*      simpleBar(scrollbar)    custom styles       */
/* ================================================ */

.shopping-page.dynamic-area > .items-container > div[data-simplebar] {
    height: 100%;
}

.simplebar-track .simplebar-scrollbar.simplebar-visible:before {
    background-color: var(--text-red);
    opacity: 1;
}

.simplebar-scrollbar {
    margin-left: 2px;
}

/* ================================================ */

.shopping-page.dynamic-area  .closing-deal {
    height: 30%;
    
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
   
}

.shopping-page.dynamic-area > .closing-deal > .overall-price {
    text-align: right;
    border: 3px solid var(--text-red);
    height: min-content;
    margin: 10px;
    padding: 0 1ch;
    cursor: default;
    align-self: center;
}

.shopping-page.dynamic-area > .closing-deal > button {
    display: block;
    margin: 0 auto;
    line-height: 2em;
    height: min-content;
    width: 50%;
    min-width: max-content;
}


.shopping-page.dynamic-area > .closing-deal > button:disabled {
    background-color: var(--text-red);
}

@media (max-width: 1024px) {

    .shopping-page.text-area  {
        grid-column: 2 / 10;
        grid-row: 1 / 3;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }
    
    .shopping-page.dynamic-area  {
        grid-column: 4 / 8;
        grid-row: 3 / 10;

        /* padding-top: 60px; */
    }

}

@media (max-width: 768px) {


    .shopping-page.dynamic-area  {
        grid-column: 3 / 9;
        grid-row: 3 / 10;

    }
}
